<template>
  <BaseHeader
    class="border-b border-slate-300"
    :class="route.name === 'data-lab-role' ? 'border-none bg-black-300' : 'bg-slate-100'"
    :menu-items="[
      {
        label: '작업자',
        to: '/data-lab/labeler',
        class: route.name === 'data-lab-role' && '!text-slate-400 hover:!text-white-950'
      },
      {
        label: '검수자',
        to: '/data-lab/reviewer',
        class: route.name === 'data-lab-role' && '!text-slate-400 hover:!text-white-950'
      }
    ]"
    :toolbar-items="[
      {
        thumbnail: data?.thumbnail,
        dropdownMenuItems: [
          [
            {
              type: 'user',
              class: 'hover:bg-white active:bg-white cursor-auto',
              media: {
                thumbnail: data?.thumbnail || '',
                title: data?.name || '',
                description: data?.email || ''
              }
            }
          ],
          ...(data?.tenants?.length
            ? [
                [
                  {
                    label: '워크스페이스 이동',
                    to: '/projects',
                    prefixIcon: 'shape:swap-2-browser',
                    prefixIconClass: 'text-slate-500',
                    prefixIconSize: '24',
                    suffixIcon: ' ',
                    onClick: handleClickToWorkerSpace
                  }
                ]
              ]
            : []),
          [
            {
              label: '계정',
              to: { name: 'account-profile' },
              prefixIcon: 'heroicons:user',
              prefixIconClass: 'text-slate-500 p-0.5',
              suffixIcon: ' '
            }
          ],
          [
            {
              label: '로그아웃',
              to: '/auth/login',
              prefixIcon: 'datamaker:arrow-right-start-on-rectangle',
              prefixIconClass: 'text-slate-500 p-0.5',
              suffixIcon: ' ',
              onClick: handleLogout
            }
          ]
        ].filter(Boolean)
      }
    ]" />
</template>

<script setup lang="ts">
import { useAPI } from '~/composables/shared/useAPI'
import { usePrefetchQuery } from '~/composables/shared/usePrefetchQuery'
import { useTokenCookies } from '~/composables/shared/useTokenCookies'
import { TOKEN } from '~/constants/shared'

const api = useAPI()
const route = useRoute()
const config = useRuntimeConfig()

const { cookie } = useTokenCookies()

const { data } = await usePrefetchQuery({
  queryKey: [api.account.constructor.name, api.account.user.retrieveMe.name],
  queryFn: () => api.account.user.retrieveMe()
})

const handleLogout = () => {
  cookie.remove(TOKEN.AUTH.value, TOKEN.AUTH.option(config.public.baseCookieDomain))
}

const handleClickToWorkerSpace = () => {
  cookie.set(TOKEN.TENANT.value, data?.tenants?.[0].code, TOKEN.TENANT.option(config.public.baseCookieDomain))
}
</script>
