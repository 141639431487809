<template>
  <slot>
    <Button
      v-bind="props"
      variant="link"
      color="slate"
      class="h-[2.5rem] w-[2.5rem] cursor-pointer border border-slate-300 p-1.5 text-slate-500"
      size="xl"
      as="NuxtLink" />
  </slot>
</template>

<script setup lang="ts">
import type { HeaderToolbarLinkProps } from './types'

const props = defineProps<HeaderToolbarLinkProps>()
</script>
