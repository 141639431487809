<template>
  <component :is="headerComponent()" />
</template>

<script setup lang="ts">
import AuthHeader from '~/components/shared/header/AuthHeader.vue'
import DefaultHeader from '~/components/shared/header/DefaultHeader.vue'
import WorkerSpaceHeader from '~/components/shared/header/WorkerSpaceHeader.vue'
import type { HeaderProps } from '~/components/shared/header/types'

const props = defineProps<HeaderProps>()

function headerComponent() {
  switch (props.variant) {
    case 'default':
      return DefaultHeader
    case 'worker':
      return WorkerSpaceHeader
    case 'auth':
      return AuthHeader
    default:
      return DefaultHeader
  }
}
</script>
