<template>
  <FormKit
    name="workspace"
    type="select"
    outer-class="!mb-0 w-80"
    :value="tenantCode"
    :options
    inner-class="!bg-slate-100"
    @input="onChangeSelectedWorkspace" />
</template>

<script setup lang="ts">
import type { FormKitOptionsItem } from '@formkit/inputs'
const props = defineProps<{ options: FormKitOptionsItem[] }>()
const tenantSlug = useRoute().params.tenant
const tenantCode = computed(() => {
  const matchedTenant = props.options.find((item) => item.slug === tenantSlug)
  return matchedTenant?.value
})
function onChangeSelectedWorkspace(token: string) {
  const matchedTenant = props.options.find((item) => item.value === token)
  navigateTo(`/${matchedTenant?.slug}/projects`)
}
</script>
