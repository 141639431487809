/**
 * 문자열에서 첫 글자만 추출하여 대문자로 변환
 * @param str 변환할 문자열
 * @returns 첫 글자의 대문자 또는 빈 문자열
 * @example
 * extractInitial('홍길동'); // '홍'
 * extractInitial('john'); // 'J'
 * extractInitial(''); // ''
 * extractInitial(); // ''
 */
export const extractInitial = (str?: string): string => {
  if (!str || str.length === 0) return ''
  return str.slice(0, 1).toUpperCase()
}
