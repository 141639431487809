import { cva } from 'class-variance-authority'

export const headerMenuLinkVariants = cva(
  'h-full rounded-none border-b-2 border-transparent px-8 text-slate-600 hover:text-slate-900',
  {
    variants: {
      active: {
        true: 'border-blue-600 text-slate-900'
      }
    }
  }
)

export const headerMenuDropdownMenuVariants = cva('-mt-2 bg-slate-100', {
  variants: {
    active: {
      true: 'border-2 border-blue-600'
    }
  }
})
