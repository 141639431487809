<template>
  <DropdownMenu class="mr-2 p-1" heading="계정" :items="items || []">
    <Button color="slate" class="p-0">
      <Avatar v-bind="avatarValue" class="w-10 border border-slate-300 !ring-0" :border="false" />
    </Button>
  </DropdownMenu>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import type { HeaderProfileDropdownMenuProps } from '~/components/shared/header/types'
import { extractInitial } from '~/utils/string'

const props = defineProps<HeaderProfileDropdownMenuProps>()

const avatarValue = computed<{ type: 'image' | 'text'; value: string }>(() => {
  if (props.thumbnail) return { type: 'image', value: props.thumbnail }

  const userName = props.items?.[0]?.[0].media?.title ?? ''
  return { type: 'text', value: extractInitial(userName) }
})
</script>
