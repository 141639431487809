<template>
  <DropdownMenu :items="items || []" :class="cn(headerMenuDropdownMenuVariants({ active: isActive }))">
    <HeaderMenuLink size="lg" :active="isActive" suffix-icon="heroicons:chevron-up-down">
      <slot />
    </HeaderMenuLink>
  </DropdownMenu>
</template>

<script setup lang="ts">
import { headerMenuDropdownMenuVariants } from '~/components/shared/header/index'
import type { HeaderMenuDropdownMenuProps } from '~/components/shared/header/types'
import { cn } from '~/lib/utils'

const props = defineProps<HeaderMenuDropdownMenuProps>()
const route = useRoute()
const isActive = computed(
  () =>
    !!props.items?.[0].some(
      ({ to }) =>
        route.path.includes(to as string) || (typeof to === 'object' && 'name' in to && route.name?.includes(to?.name))
    )
)
</script>
