<template>
  <BaseHeader
    class="h-16 border-b border-slate-300 bg-slate-100"
    :menu-items="[
      { label: '프로젝트', to: { name: 'projects' } },
      { label: '카탈로그', to: { name: 'catalog-collections' } },
      {
        label: '머신러닝',
        dropdownMenuItems: [
          [
            {
              label: '모델',
              labelClass: 'text-base',
              to: { name: 'ml-models' },
              suffixIcon: ' ',
              prefixIcon: 'heroicons:cpu-chip',
              prefixIconClass: 'text-slate-500 mr-2'
            },
            {
              label: '실험',
              labelClass: 'text-base',
              to: { name: 'ml-experiments' },
              suffixIcon: ' ',
              prefixIcon: 'heroicons:beaker',
              prefixIconClass: 'text-slate-500 mr-2'
            }
          ]
        ]
      },
      {
        label: '인프라',
        dropdownMenuItems: [
          [
            {
              label: '에이전트',
              labelClass: 'text-base',
              to: { name: 'infra-agents' },
              suffixIcon: ' ',
              prefixIcon: 'datamaker:gpu',
              prefixIconClass: 'text-slate-500 mr-2'
            }
            // TODO: 스토리지 목록 추가 시 주석 해제
            // {
            //   label: '스토리지',
            //   labelClass: 'text-base',
            //   to: { name: 'ml-experiments' },
            //   suffixIcon: ' ',
            //   prefixIcon: 'datamaker:storage',
            //   prefixIconClass: 'text-slate-500 mr-2'
            // }
          ]
        ]
      },
      { label: '플러그인', to: { name: 'plugins-neural_net' } }
    ]"
    :toolbar-items="[
      {
        icon: 'heroicons:question-mark-circle',
        to: 'https://docs.datamaker.io/docs/%EA%B0%80%EC%9E%85%ED%95%98%EA%B8%B0',
        target: '_blank'
      },
      { to: { name: 'settings-personal' }, icon: 'heroicons:cog-8-tooth' },
      {
        thumbnail: user?.thumbnail,
        dropdownMenuItems: [
          [
            {
              type: 'user',
              class: 'hover:bg-white active:bg-white cursor-auto',
              media: {
                thumbnail: user?.thumbnail,
                title: user?.name,
                description: user?.email
              }
            }
          ],
          [
            {
              to: '/data-lab/labeler',
              label: '데이터랩 이동',
              prefixIcon: 'shape:swap-2-browser',
              prefixIconClass: 'text-slate-500',
              prefixIconSize: '24',
              suffixIcon: ' ',
              onClick: handleClickToWorkerSpace
            }
          ],
          [
            {
              label: '계정',
              to: { name: 'account-profile' },
              prefixIcon: 'heroicons:user',
              prefixIconClass: 'text-slate-500 p-0.5',
              suffixIcon: ' '
            },
            {
              label: '다른 아이디로 로그인',
              class: 'hidden',
              prefixIcon: 'heroicons:arrow-path',
              prefixIconClass: 'text-slate-500 p-0.5'
            }
          ],
          [
            {
              label: '로그아웃',
              to: '/auth/login',
              prefixIcon: 'datamaker:arrow-right-start-on-rectangle',
              prefixIconClass: 'text-slate-500 p-0.5',
              suffixIcon: ' ',
              onClick: handleLogout
            }
          ]
        ]
      }
    ]"
    :workspace-options="workspaceList" />
</template>

<script setup lang="ts">
import { useAPI } from '~/composables/shared/useAPI'
import { usePrefetchQuery } from '~/composables/shared/usePrefetchQuery'
import { useTokenCookies } from '~/composables/shared/useTokenCookies'
import { TOKEN } from '~/constants/shared'

const api = useAPI()
const { cookie } = useTokenCookies()
const config = useRuntimeConfig()

const { data: workspaceList } = await usePrefetchQuery({
  queryKey: [api.worker.tenant.constructor.name, api.worker.tenant.list.name],
  queryFn: async () => {
    const response = await api.worker.tenant.list()
    return (
      response?.results?.map(({ name, code, slug, thumbnail = '' }) => ({
        label: name,
        value: code,
        slug,
        thumbnail
      })) ?? []
    )
  }
})

const { data: user } = await usePrefetchQuery({
  queryKey: [api.account.constructor.name, api.account.user.retrieveMe.name],
  queryFn: () => api.account.user.retrieveMe()
})

function handleLogout() {
  cookie.remove(TOKEN.AUTH.value, TOKEN.AUTH.option(config.public.baseCookieDomain))
  cookie.remove(TOKEN.TENANT.value, TOKEN.TENANT.option(config.public.baseCookieDomain))
}

function handleClickToWorkerSpace() {
  cookie.remove(TOKEN.TENANT.value, TOKEN.TENANT.option(config.public.baseCookieDomain))
}
</script>
