<template>
  <Button
    variant="link"
    :class="
      cn(
        $attrs?.class ?? '',
        headerMenuLinkVariants({
          active:
            active ||
            route.path.includes(to as string) ||
            (typeof to === 'object' && 'name' in to && route.name?.includes(to?.name))
        })
      )
    "
    as="NuxtLink"
    size="lg"
    color="slate"
    squared
    :to
    :suffix-icon="suffixIcon">
    <slot />
  </Button>
</template>

<script setup lang="ts">
import type { HeaderMenuLinkProps } from '~/components/shared/header/types'
import { cn } from '~/lib/utils'
import { headerMenuLinkVariants } from '.'

const { active = false } = defineProps<HeaderMenuLinkProps>()
const route = useRoute()
</script>
